/* Spinner.css */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column; /* Stack the spinner and text vertically */
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner-text {
    margin-top: 30px; /* Space between the spinner and the text */
    color: #fa4454; 
    font-size: 18px; /* Font size for the text */
    font-weight: 500; 
    text-align: center; 
    font-family: Arial, sans-serif;
    opacity: 0.7;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75); /* Add shadow for better readability */
}

.spinner-tip {
    margin-top: 20px; /* Space between the main text and the tip */
    color: #fa4454;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    font-family: Arial, sans-serif;
    opacity: 0.7;
    max-width: 60%; /* Reduce max-width to avoid cutting off */
    line-height: 1.4; /* Add line height for better readability */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
    word-wrap: break-word; /* Ensure long words break and don't overflow */
}

